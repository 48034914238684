import { useState } from "react";
import { Link } from 'carbon-components-react';
import React from "react";

export type FactSheetState = {
    modelId: string,
    factSheet: any
}

export function useFactSheet(model_id: string) {
    const [exampleState, setExampleState] = useState<FactSheetState>();

    if ( !exampleState || (exampleState.modelId !== model_id) || !exampleState.factSheet ) {
        // console.log("Retrieving FS...");
        fetch(`${process.env.PUBLIC_URL}/factsheets/${model_id}.json`)
        .then((res) =>  {
            return res.ok ? res.json() : {};
        })
        .then((fs: any) => {
            // console.log({ modelId: model_id, factSheet: fs });
            setExampleState( { modelId: model_id, factSheet: fs })
        });
    }

    return exampleState?.factSheet;
}

export type LinkableText = {
    text: string,
    url?: string
}

export const asNode = (lt: LinkableText) : React.ReactNode => {
    if (lt.url)
        return <Link href={lt.url} target="_blank">{lt.text}</Link>
    return lt.text;
}

export const slugify = ( title: string) => {
    return title.toLowerCase().replace(/ /g, "_");
}
