import React from "react";

import './SimpleContentPage.scss';

type SimpleContentPageProps = {
    title: string;
}

export const SimpleContentPage: React.FC<SimpleContentPageProps> = ( props ) => (
    <div className="bx--grid bx--grid--full-width bx--grid--no-gutter">
      <section className="bx--row stripe">
        <div className="simple-title-wrapper">
            <p className="logo-title">{props.title}</p>
        </div>
      </section>
      <hr id='content-separator'/>
      <section className="bx--row grey stripe">
        <div className="simple-content-wrapper">
            {props.children}
        </div>
      </section>
    </div>
)