import React from 'react';

export const NotFound = () => (
    <div className="bx--grid bx--grid--full-width bx--grid--no-gutter">
        <section className="bx--row">
            <div  className="bx--col-lg-16">
                <br/><br/><br/><br/><br/><br/>
                <h1 style={{textAlign: "center", fontSize: 100}}>404</h1><br/><br/><br/><br/>
                <h1 style={{textAlign: "center", fontSize: 50}}>Page not found</h1>
            </div>
        </section>
    </div>
)
