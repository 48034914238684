import { CarbonIconType, Touch_132, Api_132, Notebook32, LogoGithub32, LogoSlack32, Event32, Video32, Link32, Workspace32, Renew32, Document32, Information32, TextCreation32, List32 } from "@carbon/icons-react";

export const FS_ICON_INTRODUCTION: CarbonIconType = Information32;
export const FS_ICON_DEMO: CarbonIconType = Touch_132;
export const FS_ICON_API_DOC: CarbonIconType = Api_132;
export const FS_ICON_TUTORIAL: CarbonIconType = Notebook32;
export const FS_ICON_GITHUB: CarbonIconType = LogoGithub32;
export const FS_ICON_SLACK: CarbonIconType = LogoSlack32;
export const FS_ICON_EVENTS: CarbonIconType = Event32;
export const FS_ICON_VIDEOS: CarbonIconType = Video32;
export const FS_ICON_RESOURCES: CarbonIconType = Link32;
export const FS_ICON_EXAMPLES: CarbonIconType = Workspace32;
export const FS_ICON_PROCESS: CarbonIconType = Renew32;
export const FS_ICON_FEATURED_EXAMPLE: CarbonIconType = Document32;
export const FS_ICON_AUTHORING: CarbonIconType = TextCreation32;
export const FS_ICON_PAPERS: CarbonIconType = List32;
